import $ from "jquery";

$(window).bind('click', (e) => {
    console.log(e);
});

// Creates initial options
const createOptions = opts => Object.assign({}, {
    banner: 'Hello World',
    prompt: () => '$ > ',
    tickrate: 1000 / 60,
    buflen: 6,
    commands: {}
}, opts || {});

// Creates our textarea element
const createElement = root => {
    const el = document.createElement('div');
    el.id = "crttext";
    root.appendChild(el);
    const el2 = document.createElement('input');
    el2.id = "input";
    el2.type = 'text';
    root.appendChild(el2);

    return el;
};

// Gets the font size of an element
const getFontSize = element => parseInt(window.getComputedStyle(element)
    .getPropertyValue('font-size'), 10);

// Creates the rendering loop
const renderer = (tickrate, onrender) => {
    let lastTick = 0;

    const tick = (time) => {
        const now = performance.now();
        const delta = now - lastTick;

        if (delta > tickrate) {
            lastTick = now - (delta % tickrate);

            onrender();
        }

        window.requestAnimationFrame(tick);
    };

    return tick;
};
var encodedStr = (rawStr) => rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
});
// Pronts buffer onto the textarea
const printer = (crtdiv, buflen) => buffer => {
    if (buffer.length > 0) {
        const len = Math.min(buflen, buffer.length);
        const val = buffer.splice(0, len);

        $(crtdiv).append(encodedStr(val.join('')));

        crtdiv.scrollTop = crtdiv.scrollHeight;

        return true;
    }

    return false;
};

// Parses input
const parser = onparsed => str => {
    if (str.length) {
        const args = str.split(' ').map(s => s.trim());
        const cmd = args.splice(0, 1)[0];
        console.debug(cmd, args);
        onparsed(cmd, ...args);
    }
};

// Command executor
const executor = commands => (cmd, ...args) => cb => {
    cmd = cmd.toLowerCase();
    try {
        if (commands[cmd]) {
            let output = commands[cmd](...args);
            output = Promise.resolve(output).then((output) =>
                cb(output + '\n')
            )
                .catch(e => {
                    console.warn(e);
                    cb(`\nException: ${e.message}\n`);
                });
        } else {
            cb(`\nNo such command '${cmd}'\n`);
        }
    } catch (e) {
        console.warn(e);
        cb(`\nException: ${e.message}\n`);
    }
};
let busy = false;
// Handle keyboard events
const keyboard = (parse, crtdiv) => {
    let input = [];
    const keys = { 8: 'backspace', 13: 'enter' };
    const ignoreKey = code => code >= 33 && code <= 40;
    const key = ev => keys[ev.which || ev.keyCode];

    return {
        keydown: (ev) => {
            if (busy) return;
            if (key(ev) === 'enter') {
                const str = input.join('').trim();
                parse(str);
                input = [];
                ev.preventDefault();
            }
            else if (key(ev) === 'backspace') {
                if (input.length > 0) {
                    input.pop();
                    const oldcontent = $(crtdiv).html();
                    const newcontent = oldcontent.substring(0, oldcontent.length - 1);
                    $(crtdiv).html(newcontent);
                    ev.preventDefault();
                } else {
                    ev.preventDefault();
                }
            } else if (ignoreKey(ev.keyCode)) {
                ev.preventDefault();
            }
        },
        input: (ev) => {
            if (busy) return;
            $(crtdiv).append(ev.originalEvent.data.toLowerCase());
            $("#input").val('');
            input.push(ev.originalEvent.data.toLowerCase());
        },
    };
};

// Creates the terminal
export const terminal = async (opts) => {
    let buffer = []; // What will be output to display

    const { prompt, banner, commands, buflen, tickrate } = createOptions(opts);
    const root = document.querySelector('#terminal');
    const crtdiv = createElement(root);

    const output = (output, center) => {
        let lines = output.split(/\n/);

        const append = lines.join('\n') + '\n' + prompt();
        buffer = buffer.concat(append.split(''));
    };

    const print = printer(crtdiv, buflen);
    const execute = executor(commands);
    const onrender = () => (busy = print(buffer));
    const onparsed = (cmd, ...args) => {
        (execute(cmd, ...args))(output);
    }
    const render = renderer(tickrate, onrender);
    const parse = parser(onparsed);
    const focus = () => {
        $('#input').trigger('focus');
    };
    const kbd = keyboard(parse, crtdiv);
    const clear = () => ($(crtdiv).html(''));
    const keydown = ev => kbd.keydown(ev);
    const input = ev => kbd.input(ev);

    root.appendChild(crtdiv);

    $(crtdiv).on('blur', focus);
    $(crtdiv).on('touchstart', focus);
    $(crtdiv).on('click', focus);

    $(root).on('touchstart', focus);

    $(window).on('keydown', keydown);
    $(window).on('input', input);
    $(window).on('click', focus);
    $(window).on('touchstart', focus);

    render();
    output(banner, true);
    focus();

    return { focus, parse, clear, print: output };
};
